var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-store-button" }, [
    _vm.type === "google"
      ? _c("div", { staticClass: "channel-btn" }, [
          _c("img", {
            staticClass: "channel",
            attrs: {
              src: _vm.theme === "light" ? _vm.googleLight : _vm.googleDark,
            },
            on: {
              mouseenter: function ($event) {
                return _vm.handleAppCodeVisible(true)
              },
              mouseleave: function ($event) {
                return _vm.handleAppCodeVisible(false)
              },
              click: _vm.handleClick,
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "apple"
      ? _c("div", { staticClass: "channel-btn" }, [
          _c("img", {
            staticClass: "channel",
            attrs: {
              src: _vm.theme === "light" ? _vm.appleLight : _vm.appleDark,
            },
            on: {
              mouseenter: function ($event) {
                return _vm.handleAppCodeVisible(true)
              },
              mouseleave: function ($event) {
                return _vm.handleAppCodeVisible(false)
              },
              click: _vm.handleClick,
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.codeVisible,
            expression: "codeVisible",
          },
        ],
        staticClass: "code",
        class: _vm.codePosition,
      },
      [_c("img", { attrs: { src: _vm.getPic } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
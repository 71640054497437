<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-11-10 13:19:18 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-11-27 11:49:21 -->

<template>
    <div class="app-store-button">
        <div
            v-if="type === 'google'"
            class="channel-btn"
        >
            <img
                :src="theme === 'light' ? googleLight : googleDark"
                class="channel"
                @mouseenter="handleAppCodeVisible(true)"
                @mouseleave="handleAppCodeVisible(false)"
                @click="handleClick"
            >
        </div>

        <div
            v-if="type === 'apple'"
            class="channel-btn"
        >
            <img
                :src="theme === 'light' ? appleLight : appleDark"
                class="channel"
                @mouseenter="handleAppCodeVisible(true)"
                @mouseleave="handleAppCodeVisible(false)"
                @click="handleClick"
            >
        </div>

        <div
            v-show="codeVisible"
            class="code"
            :class="codePosition"
        >
            <img :src="getPic">
        </div>
    </div>
</template>

<script>
import webviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import Andr from './img/and.png';
import appleDark from './img/apple-d.png';
import appleLight from './img/apple-l.png';
import googleDark from './img/google-d.png';
import googleLight from './img/google-l.png';
import IosPic from './img/ios.png';

const StoreMap = {
    google: 'https://mabiliscash.onelink.me/emor/6wfwqkxb',
    apple: 'https://mabiliscash.onelink.me/emor/ajckfh3r'
};

export default {
    name: 'AppStoreButton',

    props: {
        type: {
            type: String,
            default: 'google'
        },

        theme: {
            type: String,
            default: 'light'
        },

        codePosition: {
            type: String,
            default: 'top'
        }
    },

    data() {
        return {
            codeVisible: false,
            isMobile: false,
            IosPic,
            Andr,
            appleLight,
            appleDark,
            googleLight,
            googleDark
        };
    },

    computed: {
        getPic() {
            return this.type === 'apple' ? this.IosPic : this.Andr;
        }
    },

    mounted() {
        this.isMobile = window.innerWidth < 769;
    },

    methods: {
        handleAppCodeVisible(status) {
            if (this.isMobile) return;
            this.codeVisible = status;
        },

        handleClick() {
            const storeType = this.type;
            webviewInterface.openBrowser(StoreMap[storeType]);
        }
    }
};
</script>

<style lang="scss" scoped>
$screen-md: 769px;

.app-store-button {
    position: relative;
    margin-right: 15px;

    @media screen and (max-width: $screen-md) {
        margin-right: 10px;
    }

    &:last-child {
        margin-right: 0;
    }

    .code {
        position: absolute;
        transform: translateX(-50%);
        padding: 20px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 10%);
        border-radius: 20px;
        background: #fff;
        z-index: 10;

        &.top {
            bottom: 100%;
            left: 50%;
        }

        &.bottom {
            top: 100%;
            left: 50%;
        }

        img {
            width: 145px;
            height: 145px;
        }
    }

    .channel-btn {
        width: 175px;
        height: 48px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @media screen and (max-width: $screen-md) {
            width: 131px;
            height: 36px;
            border-radius: 12px;
        }

        .channel {
            width: 100%;
            height: auto;
        }
    }
}
</style>
